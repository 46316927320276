<template>
  <div>
    <div v-if="operationId != 14 && operationId != 17 && operationId != 18">
      <OperationProcessHeaderCard />
      <hr />
      <OperationScannedList />
    </div>
    <div v-if="operationId == 14">
      <PreparePackageOperation />
    </div>
    <div v-if="operationId == 17">
      <SAVCheckResult />
    </div>
    <div v-if="operationId == 18">
      <MCToCN />
    </div>
  </div>
  <!-- <div v-if="!isWorkerLogged">
    <Lock />
  </div> -->
  <!-- <div v-if="!isWorkerLogged">
    <Lock />
  </div> -->

</template>
<script>
  import { onMounted, watch } from 'vue'
  import { ref } from '@vue/reactivity'
  import OperationProcessHeaderCard from "../../components/operation/OperationProcessHeaderCard2"
  import OperationScannedList from "../../components/operation/OperationScannedList"
  import PreparePackageOperation from "./preparePackageOperation"
  import MCToCN from "./MCToCN"
  import SAVCheckResult from "./SAVCheckResult"
  import Lock from "../../components/auth/Lock"
  import useLockRepositories from "@/composables/auth/useLockRepositories"
  import useTrackingRmaRepositories from "@/composables/warehouse/useTrackingRmaRepositories"
  import storageUtils from '@/utils/storage';


  export default {
    props: {
      divisionId: {
        type: String,
        default: ''
      },
      divisionName: {
        type: String,
        default: ''
      },
    },
    components: {
      OperationProcessHeaderCard,
      OperationScannedList,
      PreparePackageOperation,
      Lock,
      SAVCheckResult,
      MCToCN
    },
   
    setup(props) {
      // console.log('myOperation is'+props.divisionName + ' with id '+props.divisionId)
      const { isWorkerLogged } = useLockRepositories()
      const { operationId, reset, toReceivedOrToShip, productCodeDisabled } = useTrackingRmaRepositories()

      onMounted(() => {
        
        if (!!storageUtils.getWorkerUser()) {
          isWorkerLogged.value = true
        }
        const currentUrl = window.location.pathname;
        operationId.value = parseInt(currentUrl.split('/')[2])
        if(operationId.value == 3 || operationId.value == 14) {
          toReceivedOrToShip.value = true
        } else {
          toReceivedOrToShip.value = false
          productCodeDisabled.value = false
        }
      })
      watch(() => props.divisionId, (first, second) => {
        // console.log("Operation change from :", second, " to", first);
        operationId.value = parseInt(first)
        if(operationId.value == 3 || operationId.value == 14) {
          toReceivedOrToShip.value = true
        } else {
          toReceivedOrToShip.value = false
          productCodeDisabled.value = false
        }
        reset()
      });
    
      return { isWorkerLogged, operationId }
    }
  
     
  }
</script>
<style lang="scss" scoped>
hr::v-deep {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
