<template>
  <div>
    <a-modal
      v-model:visible="showScanner"
      title="Scanner"
      :footer="null"
      wrapClassName="modal-with-height"
      :afterClose="onCloseScanner">
      <div class="loading">
        <a-space>
          <a-spin size="large" />
        </a-space>
      </div>
      <div v-if="showScanner">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          ref="BarcodeReader"
        ></StreamBarcodeReader>
      </div>
    </a-modal>
    <div class="home">
      <a-form :model="formState" :rules="rules" ref="formRef" layout="vertical" :wrapperCol="{ span: 6 }">
        <a-form-item name="trackingNumber" :label="$t('SAVCheck.TrackingNumber')">
          <a-input
            :placeholder="$t('SAVCheck.enterTrackingNumber')"
            v-model:value="formState.trackingNumber"
            ref="tnumberInput"
          >
            <template #suffix>
              <a-button type="primary" @click="toggleScanner">
                <template #icon>
                  <ScanOutlined />
                </template>
              </a-button>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="wrongReasonNumber" :label="$t('SAVCheck.WrongReasonNumber')">
          <a-input
            :placeholder="$t('SAVCheck.enterNumber')"
            v-model:value="formState.wrongReasonNumber"
            style="padding-left:10px"
            ref="wrongReasonNumberInput"
          ></a-input>
        </a-form-item>
        <a-form-item name="noWarrantyNumber" :label="$t('SAVCheck.noWarrantyNumber')">
          <a-input
            :placeholder="$t('SAVCheck.enterNumber')"
            v-model:value="formState.noWarrantyNumber"
            style="padding-left:10px"
            ref="noWarrantyNumberInput"
          ></a-input>
        </a-form-item>
      </a-form>
      <a-row :gutter="24">
        <a-col :sm="24" :md="5" :xl="5" style="text-align: 'center'; margin-top: 23px;">
          <a-button key="blankRMA" type="primary" @click="submit" :disabled="!isRequired">
            {{ $t("route.submit") }}
          </a-button>
          <a-button type="link" @click="reset">
            {{ $t("route.ClearAll") }}
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
  import { ref, reactive, computed, onMounted, nextTick, watch } from 'vue'
  import { notification } from 'ant-design-vue'
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import { ScanOutlined } from '@ant-design/icons-vue';
  import { StreamBarcodeReader } from "vue-barcode-reader"
  import { useI18n } from 'vue-i18n';
  export default {
    components: {
      ScanOutlined,
      StreamBarcodeReader
    },

    setup() {
      const { createSAVCheck } = useRmaRepositories()
      const showScanner = ref(false)
      const formRef = ref()
      const tnumberInput = ref(null)
      const wrongReasonNumberInput = ref(null)
      const noWarrantyNumberInput = ref(null)
      const { t ,locale } = useI18n({ useScope: 'global' })
      const BarcodeReader = ref()

      const formState = reactive({
        trackingNumber: '',
        wrongReasonNumber: '',
        noWarrantyNumber: ''
      })

      const validateWrongReasonNumber = async (rule, value) => {
        if (!value) return Promise.reject(t('SAVCheck.enterWrongReasonNumber'))
        if (isNaN(value)) {
          return Promise.reject(t('SAVCheck.errorWrongReasonNumber'));
        } else {
          return Promise.resolve();
        }
      }

      const validateNoWarrantyNumber = async (rule, value) => {
        if (!value) return Promise.reject(t('SAVCheck.enterNoWarrantyNumber'))
        if (isNaN(value)) {
          return Promise.reject(t('SAVCheck.errorNoWarrantyNumber'));
        } else {
          return Promise.resolve();
        }
      }

      const rules = {
        trackingNumber: [
          {
            required: true,
            message: t('SAVCheck.enterTrackingNumber'),
            trigger: 'blur',
          },
        ],
        wrongReasonNumber: [
          {
            required: true,
            validator: validateWrongReasonNumber,
            trigger: ['change', 'blur']
          },
        ],
        noWarrantyNumber: [
          {
            required: true,
            validator: validateNoWarrantyNumber,
            trigger: ['change', 'blur']
          },
        ]
      }

      onMounted(() => {
        // nextTick(() => {
        //   tnumberInput.value.focus();
        // });
      })

      const isRequired = computed(() => {
        return formState.trackingNumber != '' && formState.wrongReasonNumber != '' && formState.noWarrantyNumber != ''
      })

      const toggleScanner= () => {
        showScanner.value = !showScanner.value
      }

      const onLoaded = () => {}

      const onCloseScanner = () => {}

      const onDecode = (result) => {
        formState.trackingNumber = result
        showScanner.value = false
      }

      watch(showScanner, (newValues, prevValues) => {
        if (!newValues) BarcodeReader.value.codeReader.stopStreams()
      })

      const submit = () => {
        formRef.value.validate()
          .then(async () => {
            if (formState.wrongReasonNumber.toString().indexOf('.') > -1 || formState.noWarrantyNumber.toString().indexOf('.') > -1) {
              notification.warning({
                message: t('SAVCheck.warnTitle'),
                description: t('SAVCheck.warnCheckNumber')
              })
            }
            const fromData = {
              carrier_code: formState.trackingNumber,
              wrong_reason_count: parseInt(formState.wrongReasonNumber),
              no_warranty_count: parseInt(formState.noWarrantyNumber)
            }
            try {
              const result = await createSAVCheck(fromData)
              if (result.success) {
                notification.success({
                  message: t('SAVCheck.successTitle'),
                  description: t('SAVCheck.successMessage')
                })
              } else {
                notification.error({
                  message: t('SAVCheck.errorTitle'),
                  description: t(`SAVCheck.${result.status}`)
                })
              }
            } catch {
              notification.error({
                message: t('SAVCheck.errorTitle'),
                description: t('SAVCheck.errorMessage')
              })
            }
          })
      }

      const reset = () => {
        formState.trackingNumber = ''
        formState.wrongReasonNumber = ''
        formState.noWarrantyNumber = ''
      }

      return {
        formRef,
        rules,
        formState,
        isRequired,
        onDecode,
        onLoaded,
        showScanner,
        toggleScanner,
        tnumberInput,
        wrongReasonNumberInput,
        noWarrantyNumberInput,
        locale,
        t,
        onCloseScanner,
        submit,
        reset,
        StreamBarcodeReader,
        BarcodeReader
      }
    }
  }
</script>
<style lang="scss" scoped>
.home::v-deep {
  padding-left: 10px;
  padding-right: 10px;
}

button:disabled::v-deep,
button[disabled]::v-deep {
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
}

.loading {
  left:46%;
  top:50%;
  position: absolute;
}
</style>