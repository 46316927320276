<template>
  <div>
    <a-row :gutter="24" style="width: 100%; margin: 15px 0;">
      <a-col :sm="24" :md="12" :xl="4">
        <!-- <input type="file" @change="onXlsxChange" /> -->
        <a-upload
          v-model:file-list="fileList"
          name="file"
          :multiple="true"
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          :before-upload="onXlsxChange"
          style="width: 100px;"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            {{ $t("route.ChooseFiles") }}
          </a-button>
        </a-upload>
      </a-col>
      <a-col :sm="24" :md="12" :xl="20">
        <a-button type="primary" @click="initImport" :disabled="canImport">
          {{ $t("route.Import") }}
        </a-button>
        <a-button type="link" @click="downLoadExcelTemplate()">
          {{ $t("route.DownloadExcelTemplate") }}
        </a-button>
      </a-col>
    </a-row>

    <a-modal
      v-model:visible="showScanner"
      title="Scanner"
      :footer="null"
      wrapClassName="modal-with-height"
      :afterClose="onCloseScanner"
    >
      <div class="loading">
        <a-space>
          <a-spin size="large" />
        </a-space>
      </div>
      <div v-if="showScanner">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          ref="BarcodeReader"
        >
        </StreamBarcodeReader>
      </div>
    </a-modal>
    <div class="home">
      <a-row :gutter="24" style="width: 100%">
        <a-col :sm="24" :md="12" :xl="8">
          <span style="margin-top: 10px"><b>{{ $t("route.ScanYourRMAProduct") }}</b></span>
          <a-input-search
            placeholder=""
            v-model:value="productScan"
            @pressEnter="onHandleProductScan"
            enter-button
            @search="onHandleProductScan"
            ref="productScanInput"
          >
            <template #prefix>
              <a-button type="primary" @click="toggleScanner(0)">
                <template #icon>
                  <ScanOutlined />
                </template>
              </a-button>
            </template>
          </a-input-search>
        </a-col>
        <a-col :sm="24" :md="12" :xl="8">
          <span style="margin-top: 10px"><b>{{ $t("route.ScanYourTrackingNumber") }}</b></span>
          <a-input-search
            placeholder=""
            v-model:value="TrackingScan"
            @pressEnter="onHandleTrackingScan"
            enter-button
            @search="onHandleTrackingScan"
            ref="trackingScanInput"
          >
            <template #prefix>
              <a-button type="primary" @click="toggleScanner(1)">
                <template #icon>
                  <ScanOutlined />
                </template>
              </a-button>
            </template>
          </a-input-search>
        </a-col>
      </a-row>
      <a-row :gutter="24" style="width: 100%; margin-top: 20px">
        <a-col :sm="24" :md="12" :xl="8">
          <span style="margin-top: 10px"><b>{{ $t("route.TrackingNumber") }}</b></span>
          <a-input-search
            placeholder=""
            v-model:value="trackingNumber"
            ref="tnumberInput"
          >
            <template #prefix>
              <BarcodeOutlined />
            </template>
          </a-input-search>
        </a-col>
        <a-col :sm="24" :md="12" :xl="8">
          <span style="margin-top: 10px; opacity: 0"><b>{{ $t("route.TrackingNumber") }}</b></span>
          <div>
            <a-button
              type="link"
              @click="downLoadExcelReport()"
              :disabled="!hasScannedItem"
              style="margin-right: 20px"
              :title="!hasScannedItem ? $t('transferFactory.cannotClickBecauseBlankTracking') : ''"
            >
              {{ $t("route.GenerateExcel") }}
            </a-button>
            <a-button
              key="blankRMA"
              type="primary"
              @click="onTransferFactory"
              :disabled="!hasScannedItem || trackingNumber === ''"
              style="margin-right: 20px"
            >
              <template #icon>
                <PlusOutlined />
              </template>
              {{ $t("route.PreparePackage") }}
            </a-button>
            <a-button @click="onClickClearAll">
              {{ $t("route.ClearAll") }}
            </a-button>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <LastProduct
          :lastItem="lastItem"
          :unScannedItemCount="UnScannedItemCount"
          :scannedItemCount="ScannedItemCount"
        ></LastProduct>
      </a-row>
    </div>

    <a-card :body-style="{ padding: '20px 24px 8px' }" :bordered="true">
      <div class="chart-card-header">
        <div class="meta">
          <span class="chart-card-title">
            <slot name="title">
              <span style="color: #ff4d4d"><b>*{{ $t("route.RedIsUnscannedProduct") }}</b></span>
              |
              <span style="color: #86d067"><b>*{{ $t("route.GreenIsScannedProduct") }}</b></span>
            </slot>
          </span>
        </div>
      </div>
    </a-card>
    <ProductList
      :waitingPackageData="waitingPackageData"
      :state="PreparePackageState"
    ></ProductList>
    <ProductDetail :stateData="PreparePackageState"></ProductDetail>
  </div>
</template>
<script>
import XLSX from "xlsx";
import { createVNode, ref, computed, watch, onMounted, nextTick } from "vue";
import { Modal, message, notification } from "ant-design-vue";
import useRmaRepositories from "@/composables/useRmaRepositories";
import {
  UserOutlined,
  ScanOutlined,
  ExclamationCircleOutlined,
  UserSwitchOutlined,
  BarcodeOutlined,
  CalendarOutlined,
  FileSearchOutlined,
  BorderlessTableOutlined,
  PlusOutlined,
  UploadOutlined
} from "@ant-design/icons-vue";
import { formatDate } from "@/utils/utils";
import { StreamBarcodeReader } from "vue-barcode-reader";
import ProductList from "@/components/rma/ProductList";
import LastProduct from "@/components/rma/LastProduct";
import ProductDetail from "@/components/rma/ProductDetail";
import PreparePackageState from "../rma/state/PreparePackage";
import { useI18n } from "vue-i18n";
export default {
  components: {
    ProductDetail,
    UserOutlined,
    UserSwitchOutlined,
    BarcodeOutlined,
    CalendarOutlined,
    FileSearchOutlined,
    BorderlessTableOutlined,
    ScanOutlined,
    ProductList,
    LastProduct,
    StreamBarcodeReader,
    PlusOutlined,
    UploadOutlined
  },
  setup() {
    const {
      postCanTransfer,
      postTranferFactory,
      postNeedTranfer,
      getTranferList,
      getTranferPackage,
      getTranferClear
    } = useRmaRepositories();
    const waitingPackageData = ref([]);
    const scannedItem = ref([]);
    const productScan = ref("");
    const TrackingScan = ref("");
    const lastItem = ref({});
    const showScanner = ref(false);
    const scannerType = ref(0);
    const { selectedItem } = PreparePackageState();
    const tnumberInput = ref(null);
    const productScanInput = ref(null);
    const trackingScanInput = ref(null);
    const exceldata = ref([]);
    const { t, locale } = useI18n({ useScope: "global" });
    const BarcodeReader = ref();
    const canImport = ref(true);
    const idsList = ref({});
    const fileList = ref([])

    onMounted(() => {
      init();
    });

    const toggleScanner = (type) => {
      showScanner.value = !showScanner.value;
      scannerType.value = type;
    };

    const onLoaded = () => {};

    const onCloseScanner = () => {};

    const onDecode = (result) => {
      if (scannerType.value == 0) {
        productScan.value = result;
        onHandleProductScan();
      } else if (scannerType.value == 1) {
        TrackingScan.value = result;
        onHandleTrackingScan();
      }
    };

    watch(showScanner, (newValues, prevValues) => {
      if (!newValues) BarcodeReader.value.codeReader.stopStreams();
    });

    const ScannedItemCount = computed(() => {
      return scannedItem.value.length;
    });

    const UnScannedItemCount = computed(() => {
      return waitingPackageData.value.length - scannedItem.value.length;
    });
    const hasScannedItem = computed(() => {
      return scannedItem.value.length > 0;
    });
    const trackingNumber = ref("");

    const init = async () => {
      try {
        const result = await getTranferList();
        waitingPackageData.value = [...result.data];
        scannedItem.value = [...result.data];
      } catch (error) {
        console.log(error, "status_codeon Mounted");
      }
    };

    const reset = () => {
      scannedItem.value = [];
      waitingPackageData.value = [];
      trackingNumber.value = "";
      productScan.value = "";
      TrackingScan.value = "";
      init();
    };

    const onHandleProductScan = async () => {
      productScan.value = productScan.value.replace(" ", "");
      const result = await postNeedTranfer({
        ids: [productScan.value],
      });
      if (!result.success) {
        notification.error({
          message: "RMA Error",
          description: result.message,
        });
      } else {
        notification.success({
          message: "RMA Update",
          description: t("transferFactory.dataIsSaved"),
        });
      }
      productScan.value = "";
      init();
    };

    const onHandleTrackingScan = async () => {
      TrackingScan.value = TrackingScan.value.replace(" ", "");
      const result = await postNeedTranfer({
        tracking_code: TrackingScan.value,
      });
      if (!result.success) {
        notification.error({
          message: "RMA Error",
          description: result.message,
        });
      } else {
        notification.success({
          message: "RMA Update",
          description: t("transferFactory.dataIsSaved"),
        });
      }
      TrackingScan.value = "";
      init();
    };

    const onClickClearAll = () => {
      Modal.confirm({
        title: "Are you sure to cancel the tracking?",
        icon: createVNode(ExclamationCircleOutlined),
        async onOk() {
          const result = await getTranferClear()
          if (!result.success) {
            notification.error({
              message: "RMA Error",
              description: result.message,
            });
          }
          reset();
        },
        onCancel() {},
      });
    };

    const isTrackingNumberVaild = () => {
      const isnum = !/[^a-z0-9_.@()-]/i.test(trackingNumber.value);
      if (!isnum) {
        return false;
      }
      return true;
    };

    const onTransferFactory = async () => {
      if (!isTrackingNumberVaild()) {
        notification.error({
          message: "Please Enter Vaild Tracking Number",
          description: `RMA Update Error, Please tracking cannot contain < > : " / \\ | ? * `,
        });
        return;
      }

      Modal.confirm({
        title: "Would you like to confirm the Package?",
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          const updateAllRma = waitingPackageData.value.map(async (row) => {
            return await postTranferFactory({
              id: row.id,
              tracking_code: trackingNumber.value,
              remark: "",
            }).then((data) => {
              return data;
            });
          });
          Promise.all(updateAllRma)
            .then(async (data) => {
              notification.success({
                message: "RMA Updated",
                description: "RMA Updated",
              });
              init();
            })
            .catch((err) => {
              notification.error({
                message: "RMA Error",
                description: err,
              });
              console.log(err, "error Promise All");
              init();
            });
        },
        onCancel() {},
      });
    };

    const downLoadExcelReport = async () => {
      // window.open(process.env.VUE_APP_RMA_MS_API_URL + '/sav/transfer_package?tracking_code=' + trackingNumber.value);
      const result = await getTranferPackage()
      if (result.success) {
        window.open(result.data)
      } else {
        notification.error({
          message: "RMA Error",
          description: result.message,
        });
      }
    };

    const downLoadExcelTemplate = () => {
      window.open(
        "https://weappimg.apm-monaco.cn/RMA_resource/RMA_MC2CN_Template.xlsx"
      );
    };

    const initImport = async () => {
      if (idsList.value.ids.length <= 0) return;
      const result = await postNeedTranfer(idsList.value);
      if (!result.success) {
        notification.error({
          message: "RMA Error",
          description: result.message,
        });
      } else {
        notification.success({
          message: "RMA Update",
          description: t("transferFactory.dataIsSaved"),
        });
      }
      init();
    };

    const onXlsxChange = async (event) => {
      // var file = event.target.files ? event.target.files[0] : null;
      let file = event ? event : null;
      fileList.value = [event]
      if (file) {
        canImport.value = true;
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = async (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const XLSX_data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          exceldata.value = [];
          idsList.value = {};
          let data = XLSX_data.filter((v, i) => {
            return i !== 0;
          });
          let data_check = -1;
          data.forEach((v) => {
            if (!v[0] || v[0] == "") data_check = 0;
            // if (!v[1] || v[1] == "") data_check = 1;
            // if (!v[2] || v[2] == "") data_check = 2;
          });
          if (data_check < 0) {
            exceldata.value = data;
            idsList.value = {
              ids: data.map((v) => v[0]),
            };
            canImport.value = false;
          } else if (data_check == 0) {
            notification.error({
              message: "RMA Error",
              description: t("ExpressDelivery.PleaseCheckRMAID"),
            });
          } else if (data_check == 1) {
            notification.error({
              message: "RMA Error",
              description: t("ExpressDelivery.PleaseCheckTrackingNumber"),
            });
          } else if (data_check == 2) {
            notification.error({
              message: "RMA Error",
              description: t("ExpressDelivery.PleaseCheckExpressDelivery"),
            });
          }
        };
      }
      return false;
    };

    const handleCanTransfer = async () => {
      canImport.value = true;
      const result = await postCanTransfer(idsList.value);
      if (!result.success) {
        notification.error({
          message: "RMA Error",
          description: result.message,
        });
      } else {
        notification.success({
          message: "RMA Success",
          description: t("transferFactory.clickImportButton"),
        });
        canImport.value = false;
      }
    };

    return {
      waitingPackageData,
      BarcodeOutlined,
      productScan,
      TrackingScan,
      onHandleProductScan,
      onHandleTrackingScan,
      hasScannedItem,
      trackingNumber,
      onTransferFactory,
      ScannedItemCount,
      UnScannedItemCount,
      lastItem,
      onClickClearAll,
      onDecode,
      onLoaded,
      StreamBarcodeReader,
      showScanner,
      toggleScanner,
      PreparePackageState,
      tnumberInput,
      productScanInput,
      trackingScanInput,
      locale,
      t,
      downLoadExcelReport,
      downLoadExcelTemplate,
      onXlsxChange,
      handleCanTransfer,
      exceldata,
      initImport,
      onCloseScanner,
      BarcodeReader,
      canImport,
      fileList
    };
  },
};
</script>
<style lang="scss" scoped>
.waiting_package_data_row {
  background: #ff4d4d;
}

.waiting_package_data_row.is_scanned {
  background: #86d067;
}
.home::v-deep {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-card .ant-card-body::v-deep {
  padding: 10px !important;
}

.ant-image::v-deep {
  float: right !important;
}

.wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge2 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #86d067;
  color: #fefefe;
}

.badge3 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #ff4d4e;
  color: #fefefe;
}

button:disabled::v-deep,
button[disabled]::v-deep {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

.loading {
  left: 46%;
  top: 50%;
  position: absolute;
}
</style>
<style lang="scss">
.scanner-container {
  .laser {
    top: 50%;
  }
}
.modal-with-height {
  .ant-modal-body {
    min-height: 300px;
  }
}
.ant-input-search-enter-button {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}
</style>