// product为白色或银色
const getWsProductIsWhite = (result) => {
    return result.data.Metal_Color == 'W' || result.data.Metal_Color == 'S'
}

// product为黑色或灰色
const getWsProductIsBlackAndGary = (result) => {
    return result.data.Metal_Color == 'N' || result.data.Metal_Color == 'BR'
}

// product接受褪色
const getWsProductIsColorFading = (result) => {
    const color_list = ['B', 'E', 'F', 'G', 'H', 'I', 'J', 'P', 'PA', 'T', 'U', 'V', 'Y', 'YA', 'N']
    return color_list.indexOf(result.data.Metal_Color) > -1
}

// product为金货
const getWsProductIsGold = (result) => {
    return result.data.IsGold == 1
}

// product为金货戒指
const getWsProductIsGoldRing = (result) => {
    return result.data.IsGold == 1 && result.data.product_type.toUpperCase() == 'RING'
}

// product带Lock
const getWsProductWithLock = (result) => {
    const reg = new RegExp(/^[a-zA-Z0-9]{1}[B|C|E]/, 'g')
    // 设置lastIndex保证从头开始校验
    reg.lastIndex = 0
    return reg.test(result.data.product_id)
}

// product不带Stone
const getWsProductWithStone = (result) => {
    const reg = new RegExp(/\d+$/, 'g')
    // 设置lastIndex保证从头开始校验
    reg.lastIndex = 0
    return reg.test(result.data.product_id)
}

// product带Pearl
const getWsProductWithPearl = (result) => {
    const reg = new RegExp(/PL|M+/, 'g')
    // 设置lastIndex保证从头开始校验
    reg.lastIndex = 0
    return reg.test(result.data.product_id)
}

// 集成按product info判断是否包含reason
const handleProductReason = (WsProduct, reason_data) => {
    const isWhite = getWsProductIsWhite(WsProduct)
    const isBlackAndGary = getWsProductIsBlackAndGary(WsProduct)
    const isColorFadingInWarranty = getWsProductIsColorFading(WsProduct)
    const isGold = getWsProductIsGold(WsProduct)
    const isGoldRing = getWsProductIsGoldRing(WsProduct)
    const isProductWithLock = getWsProductWithLock(WsProduct)
    const isProductWithStone = getWsProductWithStone(WsProduct)
    const isProductWithPearl = getWsProductWithPearl(WsProduct)
    //     isGoldRing为true时排除Deformed原因     && isGoldRing为false时排除Resizing原因     && 特定颜色产品排除Tarnished原因                          && product不带Lock时排除Defective Lock原因       && product不带Stone时排除Stone Missing原因        && product不带Pearl时排除Pearl Missing原因
    return !(isGoldRing && reason_data.ID == 10) && !(!isGoldRing && reason_data.ID == 17) && !(reason_data.ID == 1 && (isWhite || isBlackAndGary)) && !(!isProductWithLock && reason_data.ID == 6) && !(isProductWithStone && reason_data.ID == 2) && !(!isProductWithPearl && reason_data.ID == 3)
}

export {
    getWsProductIsWhite,
    getWsProductIsBlackAndGary,
    getWsProductIsColorFading,
    getWsProductIsGold,
    getWsProductIsGoldRing,
    getWsProductWithLock,
    getWsProductWithStone,
    getWsProductWithPearl,
    handleProductReason
}