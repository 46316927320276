<template>
  <div class="home">
    <a-row>
      <a-col :sm="24" :md="24" :xl="24" style="padding-left:10px; padding-right:10px; padding-bottom: 5px;padding-top: 5px;">
        <a-row :gutter="24">
          <a-col :sm="24" :md="12" :xl="9" style="margin:15px 0" v-if="operationId == 4 || operationId == 7 || operationId == 8">
            <input type="file" @change="onXlsxChange" />
            <a-button type="primary" @click="initImport">
              {{ $t("route.Import") }}
            </a-button>
            <a-button type="link" @click="downLoadExcelTemplate()">
              {{ $t("route.DownloadExcelTemplate") }}
            </a-button>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :sm="24" :md="12" :xl="9" v-if="toReceivedOrToShip">
            <span style="margin-top: 10px"><b>{{ $t("route.TrackingNumber") }} </b></span>
            <a-input-search
              v-model:value="trackingNumber"
              placeholder=""
              ref="tnumberInput"
              :disabled="trackingCodeDisabled"
              enter-button
              @input="onClearSpaceSymbol($event, 'trackingNumber')"
              @search="onPressEnterTrackingScan"
              @pressEnter="onPressEnterTrackingScan"
            >
              <template #prefix>
                <a-button type="primary" v-if="toReceivedOrToShip" @click="toggleScanner(1)">
                  <template #icon>
                    <ScanOutlined />
                  </template>
                </a-button>
                <a-button type="primary" v-else>
                  <template #icon>
                    <BarcodeOutlined />
                  </template>
                </a-button>
              </template>
            </a-input-search>
          </a-col>
          <a-col :sm="24" :md="12" :xl="9" v-if="toReceivedOrToShip">
            <span style="margin-top: 10px"><b>{{ $t("route.ScanYourRMAProduct") }}</b></span>
            <a-input-search
              v-model:value="productScanned"
              placeholder=""
              ref="rmaNumberInput"
              :disabled="productCodeDisabled"
              enter-button
              @input="onClearSpaceSymbol($event, 'productScanned')"
              @search="onPressEnterProductScan"
              @pressEnter="onPressEnterProductScan"
            >
              <template #prefix>
                <a-button type="primary" v-if="toReceivedOrToShip" :disabled="productCodeDisabled" @click="toggleScanner(2)">
                  <template #icon>
                    <ScanOutlined />
                  </template>
                </a-button>
                <a-button type="primary" v-else>
                  <template #icon>
                    <BarcodeOutlined />
                  </template>
                </a-button>
              </template>
            </a-input-search>
          </a-col>
          <a-col :sm="24" :md="12" :xl="9" v-if="!toReceivedOrToShip">
            <span style="margin-top: 10px"><b>{{ $t("route.ScanYourRMAProduct") }}</b></span>
            <a-input-search
              v-model:value="productScanned"
              placeholder=""
              ref="rmaNumberInput2"
              enter-button
              @input="onClearSpaceSymbol($event, 'productScanned')"
              @search="onPressEnterProductTrackScan"
              @pressEnter="onPressEnterProductTrackScan"
            >
              <template #prefix>
                <a-button type="primary" >
                  <template #icon>
                    <BarcodeOutlined />
                  </template>
                </a-button>
              </template>
            </a-input-search>
          </a-col>
          <a-col :sm="24" :md="24" :xl="toReceivedOrToShip == false ? 12 : 6" style="text-align: 'center'; margin-top: 23px;">
            <a-button v-if="toReceivedOrToShip" type="primary" @click="onclickValidateButton" :disabled="validateButtonDisabled">
              {{ $t("route.Validate") }}
            </a-button>
            <a-button v-if="!toReceivedOrToShip" type="primary" @click="onclickCompletedButton" :disabled="completedButtonDisabled">
              {{ $t("route.Completed") }}
            </a-button>
            <a-button type="link" @click="onClickClearAll">
              {{ $t("route.ClearAll") }}
            </a-button>
            <a-button type="link" @click="downLoadExcelReport()">
              {{ $t("route.GenerateExcel") }}
            </a-button>
          </a-col>
        </a-row>
      </a-col>
      <a-col :sm="24" :md="16" :xl="16">
        <a-card class="card-operation" :bordered="false">
          <a-row :gutter="24">
            <a-col :sm="24" :md="10" :xl="10" :span="12">
              <a-row :gutter="24"><span style="margin-bottom: 20px"><b>{{ $t("route.LatestProductScanned") }}</b></span></a-row>
              <a-row :gutter="24"><span><FileSearchOutlined style="font-size:30px; margin-bottom:10px;"/>&nbsp &nbsp &nbsp {{ latestProductScan.product_sku }} {{ ( latestProductScan.product_size ) }} {{ ( latestProductScan.is_scanned ) }}</span></a-row>
              <a-row :gutter="24"><span><BorderlessTableOutlined style="font-size:30px; margin-bottom:10px;"/>&nbsp &nbsp &nbsp {{ latestProductScan.rma_id }}</span></a-row>
              <a-row :gutter="24"><span><CalendarOutlined style="font-size:30px;"/>&nbsp &nbsp &nbsp{{ latestProductScan.receive_item_date }}</span></a-row>
            </a-col>
            <a-col :sm="24" :md="14" :xl="14" :span="12">
              <div style="float:right; min-heigth: 223px;">
                <a-image class="img_last_product_scan" width="135px" size="large" shape="square" :src="latestProductScan.product_image"/>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="8" :xl="8" >
        <a-card :bordered="false">
          <a-row >
            <a-col :span="24" style="text-align: center;margin-bottom: 20px;">
              <a-row :gutter="24">
                <a-col :sm="24" :md="12" :xl="12" :span="12">
                  <div style="min-heigth: 223px;margin-top:25px;">
                    <b>{{ $t("route.Adding") }}</b>
                  </div>
                </a-col>
                <a-col :sm="24" :md="12" :xl="12" :span="12">
                  <div class="wrapper2">
                    <span class="badge2" style="font-weight: 900">{{ addingProductScanned }}</span>
                  </div>
                </a-col>
              </a-row>
              <!-- <div class="wrapper2">
                <span class="badge2">5</span> &nbsp &nbsp &nbsp <b>Added</b>
              </div>  -->
            </a-col>
            <a-col :span="24" style="text-align: center;">
              <a-row :gutter="24">
                <a-col :sm="24" :md="12" :xl="12" :span="12">
                  <div style="min-heigth: 223px;margin-top:25px;">
                    <b>{{ $t("route.Pending") }} </b>
                  </div>
                </a-col>
                <a-col :sm="24" :md="12" :xl="12" :span="12">
                  <div class="wrapper2">
                    <span class="badge3" style="font-weight: 900">{{ pendingProductScanned }}</span>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <a-modal
      v-if="toReceivedOrToShip"
      v-model:visible="showScanner"
      title="Scanner"
      :footer="null"
      wrapClassName="modal-with-height"
      :afterClose="onCloseScanner"
    >
      <div class="loading">
        <a-space>
          <a-spin size="large" />
        </a-space>
      </div>
      <div v-if="showScanner">
        <StreamBarcodeReader
          @decode="onDecode"
          @loaded="onLoaded"
          ref="BarcodeReader"
        ></StreamBarcodeReader>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { UserOutlined, UserSwitchOutlined, BarcodeOutlined, CalendarOutlined, FileSearchOutlined, BorderlessTableOutlined, ScanOutlined } from '@ant-design/icons-vue';
  import { onMounted, watch, ref, createVNode, reactive ,toRefs, nextTick} from 'vue'
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import useTrackingRmaRepositories from "@/composables/warehouse/useTrackingRmaRepositories"
  import { Modal, notification } from 'ant-design-vue';
  import { formatDate } from '@/utils/utils';
  import { useI18n } from 'vue-i18n'
  import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
  import { OPERATION_DIVISION } from '@/store/constants'
  import { StreamBarcodeReader } from "vue-barcode-reader"
  import XLSX from "xlsx"
  import { handleProductReason } from '@/utils/getWsProducts';

  export default {
    components: {
      UserOutlined,
      UserSwitchOutlined,
      BarcodeOutlined,
      CalendarOutlined,
      FileSearchOutlined,
      BorderlessTableOutlined,
      ScanOutlined,
      StreamBarcodeReader
    },
    setup() {
      const headerState = reactive({
        isLogin : false,
        userScan : "",
        productScan:""
      });

      const tnumberInput = ref(null)
      const rmaNumberInput = ref(null)
      const rmaNumberInput2 = ref(null)
      const { t ,locale } = useI18n({ useScope: 'global' })
      const ScannerType = ref(1)
      const showScanner = ref(false)
      const BarcodeReader = ref()
      const exceldata = ref(null)

      // alert(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));

      const { rmas, rma_tracking, rma_reasons, updateRmaStatus, rma_trackingNumber, getRmaByTrackingCode, getRma, generateExcel, postRmaSavCheck, uploadGoldImage, getSubReasonsList, getWsProduct } = useRmaRepositories()
      const { trackingNumber, latestProductScan, pendingProductScanned, addingProductScanned, operationId, validateButtonDisabled,
              completedButtonDisabled, productCodeDisabled, trackingCodeDisabled, reset, productScanned, toReceivedOrToShip } = useTrackingRmaRepositories()

      const onPressEnterTrackingScan = async () => {
        const valuue = await getRmaByTrackingCode(trackingNumber.value, operationId.value)
        if (valuue.value.data === null) {
          reset()
          return notification.error({
            message: 'Error',
            description: "RMA data is not found"
          })
        }
        addingProductScanned.value = valuue.value.data.filter(x => x.rma_status_id >= 3).length
        pendingProductScanned.value = rma_tracking.value.data.length - valuue.value.data.filter(x => x.rma_status_id >= 3).length
        trackingCodeDisabled.value = true
        productCodeDisabled.value = false
        nextTick(() => {
          rmaNumberInput.value.focus();
        });
      }

      const onClickClearAll = () => {
        Modal.confirm({
          title: 'Are you sure to cancel the tracking?',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            reset()
          },
          onCancel() {},
        });
      }

      const downLoadExcelReport = async () => {
        let report_id = undefined;
        // console.log("operation id is" ,operationId.value , operationId.value === 6, operationId.value === "6")
        if (operationId.value > 2 && operationId.value < 7 ) { // IF true Reapir Process
          report_id = 0;
        }

        if (operationId.value > 6 && operationId.value < 14) { // IF true Plating Process
          report_id = 1;
        } 

        if (report_id != undefined ) {
          const result= await generateExcel(report_id,rma_tracking.value.data)
          if (result.url) {
            window.open(result.url)
          } else {
            console.log("No url found")
          }
        } else {
          console.log("No report found")
        }
      }

      const downLoadExcelTemplate = () => {
        window.open('https://weappimg.apm-monaco.cn/RMA_resource/RMA_import_template.xlsx')
      }

      const onPressEnterProductTrackScan = async () => {
        if (productScanned.value.length <= 0) {
          notification.error({
            message: 'Error',
            description: "Please enter the RMA code"
          })
          return
        }
        if (productScanned.value.length <= 10) {
          notification.error({
            message: 'Error',
            description: "Input at least 10 characters"
          })
          return
        }
        getRmaByProduct(productScanned.value)
        productScanned.value = ""
      }

      const getRmaByProduct = async (contents) => {
        const result = await getRma(contents)
        if (result.data.length > 0) {
          // console.log(rma_tracking.value.data)
          if (rma_tracking.value.data == undefined) {
            rma_tracking.value.data = []
          }
          const val = rma_tracking.value.data.find(x => x.RmaID === contents)
          if (val != undefined) {
            notification.warning({
              message: 'Warning',
              description: "Product already Scanned"
            })
            return
          }
          if (result.data[0].rma_status_id < 3) {
            notification.warning({
              message: 'Warning',
              description: "This product is not shipped yet"
            })
            return
          }
          if (result.data[0].rma_status_id >= 14) {
            notification.warning({
              message: 'Warning',
              description: "This product is already shipped to the store"
            })
            return
          }
          // console.log(result.data[0])
          result.data[0].is_scanned = true
          if (parseInt(operationId.value) == 13 && result.data[0].rma_gold_goods_history.id > 0) {
            result.data[0].gold_weight_after_repaired = ''
            result.data[0].uploadGoldWeightImage = []
          }
          rma_tracking.value.data.unshift(result.data[0])
          completedButtonDisabled.value = false;
          addingProductScanned.value++;
          completedButtonDisabled.value = false;
          validateButtonDisabled.value = false;
          latestProductScan.product_sku = result.data[0].product_sku
          latestProductScan.product_size = result.data[0].product_size
          latestProductScan.rma_id = result.data[0].RmaID
          latestProductScan.receive_item_date = formatDate(result.data[0].receive_item_date)
          latestProductScan.product_image = "https://imgcdn.apm-monaco.cn/products-images/" + result.data[0].product_sku + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
        }
      }

      const onPressEnterProductScan = async () => {
        if (rma_tracking.value.data.length <= 0) {
          notification.error({
            message: 'Error',
            description: "Please scan the tracking code first"
          })
        }
        productScanned.value = productScanned.value.replace(' ', '')
        const val = rma_tracking.value.data.find(x => x.RmaID === productScanned.value)

        if (val == undefined) {
          notification.error({
            message: 'Error',
            description: "Incorrect Product"
          })
        } else {
          if (val.is_scanned) {
            notification.warning({
              message: 'Warning',
              description: "Product already scanned"
            })
          } else {
            val.is_scanned = true
            pendingProductScanned.value--;
            addingProductScanned.value++;
            completedButtonDisabled.value = false;
            validateButtonDisabled.value = false;
            latestProductScan.product_sku = val.product_sku
            latestProductScan.product_size = val.product_size
            latestProductScan.rma_id = val.RmaID
            latestProductScan.receive_item_date = formatDate(val.receive_item_date)
            latestProductScan.product_image = "https://imgcdn.apm-monaco.cn/products-images/" + val.product_sku + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76"
            // get factory reason list
            val.reason_list = await handleProductReasons(val)
            // get subreason when scan product
            val.subReasonList = []
            if (val.FactoryReasons.length > 0) {
              for (let i = 0; i < val.FactoryReasons.length; i++) {
                const result = await getSubReasonsList(val.FactoryReasons[i])
                if (result.success) val.subReasonList = val.subReasonList.concat(result.data.filter(v => v.is_active))
              }
            }
            rma_tracking.value.data = rma_tracking.value.data.filter((data) => {
              return data.id !== val.id
            })
            rma_tracking.value.data.unshift(val)
          }
        }
        productScanned.value = "";
      }

      const onclickCompletedButton = () => {
        // 维修完成（operationId为13）时才校验Gold产品信息完整
        try {
          rma_tracking.value.data.forEach(v => {
            if (parseInt(operationId.value) == 13 && v.rma_gold_goods_history.id > 0 && v.gold_weight_after_repaired == '') {
              throw(t('createRMA.TotalWeightAreMandatory'))
            } else if (parseInt(operationId.value) == 13 && v.rma_gold_goods_history.id > 0 && v.uploadGoldWeightImage.length == 0) {
              throw(t('route.goldWeightImageMandatory'))
            }
          })
        } catch (error) {
          return notification.error({
            message: 'RMA Error',
            description: error
          })
        }

        Modal.confirm({
          title: 'Are you sure to validate?',
          icon: createVNode(ExclamationCircleOutlined),

          onOk() {
            const updateAllRma = rma_tracking.value.data.map((row) => {
              if (row.is_scanned) {
                const post_data = {
                  id: row.id,
                  rma_status_id: parseInt(operationId.value),
                  retail_carrier_code: row.retail_carrier_code
                }
                if (parseInt(operationId.value) == 13 && row.rma_gold_goods_history.id > 0) {
                  const formData = new FormData();
                  formData.append('rma_item_id', row.id)
                  formData.append('attachment', row.uploadGoldWeightImage[0])
                  formData.append('catalog', 'gold_goods:sav')
                  uploadGoldImage(formData)
                  post_data.rma_gold_goods_info = JSON.stringify({
                    gold_weight_after_repaired: row.gold_weight_after_repaired
                  })
                }
                return updateRmaStatus(post_data).then((data) => {
                  return data
                });
                // return updateRmaStatus(row.id, parseInt(operationId.value), row.retail_carrier_code).then((data) => {
                //   return data
                // });
              }
            });

            Promise.all(updateAllRma).then(async (data) => {
              // console.log(data, "update Rma response");
              notification.success({
                message: 'RMA Updated',
                description: "RMA Updated"
              })
              reset()
            }).catch((err) => {
              notification.error({
                message: 'RMA Error',
                description: err
              })
              console.log(error, "error Promise All")
            })
          },
          onCancel() {},
        });
      }

      const onclickValidateButton = () => {
        const scanProduct = rma_tracking.value.data.filter(v => v.is_scanned && !v.is_saved && v.FactorySubReasons.length > 0)
        if (scanProduct.length === 0) {
          return notification.error({
            message: 'RMA Error',
            description: t('reasonManage.pleaseSelectSubReason')
          })
        }
        Modal.confirm({
          title: 'Are you sure to validate?',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            const updateAllRma = rma_tracking.value.data.map((row) => {
              if (row.is_scanned && !row.is_saved) {
                const post_data = {
                  id: row.id,
                  rma_status_id: parseInt(operationId.value),
                  retail_carrier_code: trackingNumber.value
                }
                return updateRmaStatus(post_data).then((data) => {
                  return data
                });
                // return updateRmaStatus(row.id, parseInt(operationId.value), trackingNumber.value).then((data) => {
                //   return data
                // });
              }
            });
            ValidateConfirmReasons()
            Promise.all(updateAllRma).then(async (data) => {
              // console.log(data, "update Rma response");
              notification.success({
                message: 'RMA Updated',
                description: "RMA Updated"
              })
              reset()
            }).catch((err) => {
              notification.error({
                message: 'RMA Error',
                description: err
              })
              console.log(error, "error Promise All")
            })
          },
          onCancel() {},
        });
      }

      const ValidateConfirmReasons = async () => {
        const data_list = []
        rma_tracking.value.data.map((row) => {
          if (row.is_scanned && !row.is_saved) {
            data_list.push({
              rma_item_id: row.id,
              rma_item_reason_id: row.FactoryReasons,
              rma_reason_subreason_id: row.FactorySubReasons,
              original_produce_factory: row.OriginalProduceFactory,
              quality_investigate: row.QualityInvestigate,
            })
          }
        });
        const result = await postRmaSavCheck({
          carrier_code: trackingNumber.value,
          items: data_list
        })
        notification[result.success ? 'success' : 'error']({
          message: result.success ? 'RMA Updated' : 'RMA Error',
          description: result.success ? 'RMA reason was confirmed' : 'RMA reason confirm failure'
        })
      }

      watch(operationId, (newValues, prevValues) => {
        if (newValues != 14) {
          if(prevValues == 3) {
            nextTick(() => {
              if (tnumberInput.value!==null) {
                tnumberInput.value.focus();
              }
            });
          } else {
            nextTick(() => {
              if (rmaNumberInput2.value!==null) {
                rmaNumberInput2.value.focus();
              }
            });
          }
        }
      })

      const toggleScanner = (type) => {
        ScannerType.value = type
        showScanner.value = !showScanner.value
      }

      const onLoaded = () => {}

      const onDecode = (result) => {
        console.log(result)
        if (ScannerType.value === 1) {
          trackingNumber.value = result
          onPressEnterTrackingScan()
        } else if (ScannerType.value === 2) {
          productScanned.value = result
          onPressEnterProductScan()
        }
        showScanner.value = false
      }

      const onCloseScanner = () => {}

      watch(showScanner, (newValues, prevValues) => {
        if (!newValues) BarcodeReader.value.codeReader.stopStreams()
      })

      const initImport = async () => {
        if (exceldata.value.length <= 0) return
        exceldata.value.forEach(excelvalue => {
          getRmaByProduct(excelvalue[0])
        });
        // const bulkExcelAction = exceldata.value.map(async (excelvalue) => {
        //   const isRMAinWaitingList = waitingPackageData.value.find((PackageData) => PackageData.RmaID === excelvalue[0])
        //   await getRmaByProduct(excelvalue[0])
        //   return updateRmaStatus(rma_tracking.value.data[0].id, parseInt(operationId.value), '').then((data) => {
        //     return {success: true, id: excelvalue[0], data: data}
        //   }).catch((error) => {
        //     rma_tracking.value.data[0].is_scanned = false
        //     return {success: false, error: error, id: excelvalue[0]}
        //   })
        // })

        // try {
        //   const bulkExcelResponse = await Promise.all(bulkExcelAction)
        //   var successCount = 0
        //   bulkExcelResponse.map((res) => {
        //     if (res.success) {
        //       successCount +=1
        //     }
        //   })
        //   notification.success({
        //     message: 'RMA Updated',
        //     description: successCount + "/" + bulkExcelResponse.length + " of RMA Updated"
        //   })
        //   // console.log(bulkExcelResponse, "bulkExcelResponse")
        // } catch (error) {
        //   console.log(error)
        // }
      }

      const onXlsxChange = async(event) => {
        var file = event.target.files ? event.target.files[0] : null;
        if (file) {
          const reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            exceldata.value = data.filter((v, i) => {
              return i !== 0
            })
          }
        }
      }

      const onClearSpaceSymbol = (e, val) => {
        // 输入框绑定值需要是从useTrackingRmaRepositories中引入的
        useTrackingRmaRepositories()[val].value = e.target.value.replace(/\s/g, '')
      }

      const handleProductReasons = async (prod) => {
        const WsProduct = await getWsProduct(prod.product_sku, prod.product_size)
        const reasons = []
        for(let j = 0; j < rma_reasons.value.data.length; j++) {
          const item = rma_reasons.value.data[j]
          // 按product info判断是否包含reason       && 不含Color Fading或Color Fading Under Warranty时排除Color Fading Under Warranty保留Color Fading      && 包含Color Fading时排除Color Fading Under Warranty         && 包含Color Fading Under Warranty时排除Color Fading
          if (handleProductReason(WsProduct, item) && !(prod.FactoryReasons.indexOf(12) == -1 && prod.FactoryReasons.indexOf(16) == -1 && item.ID == 16) && !(prod.FactoryReasons.indexOf(12) > -1 && item.ID == 16) && !(prod.FactoryReasons.indexOf(16) > -1 && item.ID == 12)) {
            reasons.push(item)
          }
        }
        return reasons
      }

      return {
        ...toRefs(headerState),
        rma_tracking,
        rma_trackingNumber,
        onPressEnterProductScan,
        onPressEnterTrackingScan,
        trackingNumber, latestProductScan, pendingProductScanned, addingProductScanned, completedButtonDisabled,
        onClickClearAll, trackingCodeDisabled, productCodeDisabled, validateButtonDisabled, onclickCompletedButton,
        onclickValidateButton, onPressEnterProductTrackScan, OPERATION_DIVISION, operationId, productScanned, toReceivedOrToShip,
        downLoadExcelReport, tnumberInput, rmaNumberInput, rmaNumberInput2, t, locale,
        ScannerType, showScanner, toggleScanner, onLoaded, onDecode, onCloseScanner, BarcodeReader,
        ValidateConfirmReasons,
        initImport,
        onXlsxChange,
        downLoadExcelTemplate,
        onClearSpaceSymbol
      }
    }
  }
</script>

<style lang="scss" scoped>
// .ant-card {
//   background-color: #EFF2F5;
// }

.home::v-deep {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-card .ant-card-body::v-deep {
  padding: 10px!important;
}

.ant-image::v-deep {
  float: right!important;
}

.wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge2 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #86D067;
  color: #fefefe;
}

.badge3 {
  display: inline-block;
  border-radius: 50%;
  font-size: 20px;
  padding: 10px;
  min-width: 55px;
  text-align: center;
  background: #FF4D4E;
  color: #fefefe;
}

button:disabled::v-deep,
button[disabled]::v-deep {
  border: 1px solid #999999!important;
  background-color: #cccccc!important;
  color: #666666!important;
}

.loading {
  left:46%;
  top:50%;
  position: absolute;
}
</style>