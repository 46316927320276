<template>
  <div class="content-wrapper">
    <div class="login-content block-center">
      <div class="login">
        <div>
          <a-form 
            @submit="login" 
            :rules="rules"  
            :model="formData"
          >
            <a-form-item>
              <a-input
                size="large"
                type="text"
                placeholder="Worker ID"     
                v-model:value="formData.workerId"      
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                @click="onClickUnlockButton"
              >
                Unlock
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { reactive } from "vue";
  import { notification } from 'ant-design-vue';
  import router from '../../router'
  import useLockRepositories from "@/composables/auth/useLockRepositories"
  import {
    authRmaWorker
  } from "@/services/rmaRepositories";
  import storageUtils from '@/utils/storage';
  import { useStore } from 'vuex'


  export default {
    data() {
      return {
      
      }
    },
    setup() {
      const store = useStore()

      const onClickUnlockButton = async () => {
        // formData.workerId is same as barocde in backend
        if(!formData.workerId){
          notification.error({
            message: 'Error',
            description: "Enter Barcode"
          })
          return
        }

        // call to api check the abercode is there
        var result =  await authRmaWorker({"barcode":formData.workerId});

        if(result){
          if(!result.success){
            notification.error({
              message: 'Error Login',
              description:result.message
            })
          }
        }

        var newName = localStorage.getItem("last_name")
        var subName = '(' + result.data.WorkerName + ')'
        localStorage.setItem("last_name", newName);

        // store.dispatch('auth/setName',localStorage.getItem("last_name"))
        store.dispatch('auth/setSubname', subName)
        
        
        if (!formData.workerId  || (formData.workerId !== result.data.Barcode)) {
          notification.error({
            message: 'Error',
            description: "Login Failed.Mis match of worker id"
          })
          return
        } else {

          result.data.Permission  = result.data.Permission.split(',')
        
          storageUtils.setWorkderUser(JSON.stringify(result.data))
          storageUtils.setLoginToken(result.token)
          isWorkerLogged.value = true
          // router.push("/home")
          router.push('/operation/dashboard')
        }
      }
      const formData= reactive({
        workerId: '',
      });

      const { isWorkerLogged } = useLockRepositories()

      return { formData, onClickUnlockButton, isWorkerLogged }
    },
  }
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/auth/auth";
  .content-wrapper {
    background: none !important
  }
</style>